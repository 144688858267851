@font-face {
  font-family: 'e-Ukraine';
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url('../public/assets/fonts/e-Ukraine/e-Ukraine-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url('../public/assets/fonts/e-Ukraine/e-Ukraine-UltraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url('../public/assets/fonts/e-Ukraine/e-Ukraine-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url('../public/assets/fonts/e-Ukraine/e-Ukraine-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url('../public/assets/fonts/e-Ukraine/e-Ukraine-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'e-Ukraine';
  src: local('Pacifico Regular'), local('Pacifico-Regular'), url('../public/assets/fonts/e-Ukraine/e-Ukraine-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

*,
div {
  font-family: "e-Ukraine", sans-serif, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "e-Ukraine", sans-serif, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vH;
}

* {
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  height: 5px;
}

*::-webkit-scrollbar-track {
  background-color: white;
}